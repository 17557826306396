
import { Component, Vue } from 'vue-property-decorator';
import BrandingForm from '@/components/branding/BrandingForm.vue';
import Anchor from '@/components/shared/Anchor.vue';
import PageHeader from '@/components/shared/PageHeader.vue';
import PageHeaderAction from '@/components/shared/PageHeaderAction.vue';
import PageHeaderNavigationAction from '@/components/shared/PageHeaderNavigationAction.vue';
import { Branding } from '@govflanders/mbp-admin-panel-shared';
import { getBrandingTemplate } from '@/utility/branding';
import ValidationErrorNotification from '@/components/shared/ValidationErrorNotification.vue';
import { cloneDeep } from 'lodash';
import { mixins } from 'vue-class-component';
import PendingChangesGuard from '@/utility/forms/PendingChangesGuard.vue';

@Component({
  components: {
    Anchor,
    BrandingForm,
    PageHeader,
    PageHeaderAction,
    PageHeaderNavigationAction,
    ValidationErrorNotification,
  },
})
export default class CertificateAdd extends mixins(PendingChangesGuard) {
  value: Branding | null = null;
  initialValue: Branding | null = null;

  private created() {
    this.initValue();
    this.initialValue = cloneDeep(getBrandingTemplate());
  }

  private initValue() {
    this.value = cloneDeep(getBrandingTemplate());
  }

  public revert() {
    this.initValue();
  }

  public save() {
    this.$store
      .dispatch('branding/create', this.value)
      .then(createdValue => {
        this.initialValue = createdValue;
        this.value = createdValue;
        this.$toast.success(this.$t('branding.create.created.title'));
        this.$router.push({ name: 'branding.edit', params: { id: createdValue.id } });
      })
      .catch(e => {
        console.error(e);
        this.$toast.error(this.$t('branding.create.error'));
      });
  }
}
